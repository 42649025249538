@tailwind base;
@tailwind components;

@font-face {
    font-family: 'icomoon';
    src:  url('assets/fonts/icomoon.eot?kyqqus');
    src:  url('assets/fonts/icomoon.eot?kyqqus#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?kyqqus') format('truetype'),
    url('assets/fonts/icomoon.woff?kyqqus') format('woff'),
    url('assets/fonts/icomoon.svg?kyqqus#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.to-profile-arrow {
    animation: bounce 4s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-16px);
    }
    60% {
        transform: translateY(-8px);
    }
}

.icon-phone:before {
    content: "\e942";
}
.icon-envelop:before {
    content: "\e945";
}
.icon-github:before {
    content: "\eab0";
}
.icon-linkedin:before {
    content: "\eac9";
}

.client-list {
    @apply font-light text-base mt-8 flex flex-wrap justify-center;
}

.client {
    @apply mb-8 p-8 w-full bg-white rounded-lg shadow-lg;
}

.client h4 {
    @apply  font-heading font-bold text-xl mb-2;
}

.client p {
    @apply mb-4;
}

.skills-list {
    @apply font-light text-base mt-8 flex flex-wrap justify-center;
}

.skill {
    @apply mb-8 p-8 w-full bg-creme rounded-lg shadow-lg text-center;
}

.skill h3 {
    @apply font-heading font-bold text-xl mb-2;
}

.skill p {
    @apply mb-1;
}

@screen md {
    .client-list, .skills-list {
        @apply text-lg mt-12 ;
    }

    .client {
        @apply rounded-lg;
        width: calc(50% - theme('spacing.4'));
    }

    .client:nth-child(odd) {
        margin-right: theme('spacing.2');
    }

    .client:nth-child(even) {
        margin-left: theme('spacing.2');
    }

    .skill {
        @apply w-5/12 rounded-lg;
        @apply mr-4;
    }
}

@tailwind utilities;